<script  setup lang="ts">
import Swal from 'sweetalert2'

useHead({ title: 'Iniciar Sesión' })

const authStore = useAuthStore()
const email = ref('')
const password = ref('')

const showPassword = ref(false)

async function login() {
  const result = await authStore.login(email.value, password.value)
  if (result) {
    Swal.fire({
      icon: 'warning',
      confirmButtonText: 'Aceptar',
      title: 'Credenciales incorrectas.',
      text: 'Tu contraseña o tu correo son incorrectos. Por favor vuelve a verificar.',
      padding: '2em',
    })
  }
}

async function togglePassword() {
  showPassword.value = !showPassword.value
}

function _logout() {
  authStore.logout()
}
</script>

<template>
  <NuxtLayout name="auth">
    <div class="relative flex min-h-screen items-center justify-center px-6 py-10 dark:bg-[#060818] sm:px-16">
      <img
        src="/assets/images/auth/coming-soon-object1.png"
        alt="image"
        class="absolute left-0 top-1/2 h-full max-h-[893px] -translate-y-1/2"
      >
      <img
        src="/assets/images/auth/coming-soon-object2.png"
        alt="image"
        class="absolute left-24 top-0 h-40 md:left-[30%]"
      >
      <img
        src="/assets/images/auth/coming-soon-object3.png"
        alt="image"
        class="absolute right-0 top-0 h-[300px]"
      >
      <img
        src="/assets/images/auth/polygon-object.svg"
        alt="image"
        class="absolute bottom-0 end-[28%]"
      >
      <div
        class="relative w-full max-w-[970px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]"
      >
        <div class="relative flex flex-col justify-center rounded-md bg-white/60 px-6 py-20 backdrop-blur-lg dark:bg-black/50 lg:min-h-[558px]">
          <div class="absolute end-6 top-6">
            <LocaleSelector />
          </div>
          <div class="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div class="max-w-[340px]">
              <div class="mb-10">
                <h1 class="text-xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">
                  {{ $t('loginOneTitle') }}
                </h1>
                <p class="text-base font-bold leading-normal text-white-dark">
                  {{ $t('loginTwoTitle') }}
                </p>
              </div>
              <form
                class="space-y-5 dark:text-white"
                @submit.prevent="login"
              >
                <div>
                  <label for="Email">{{ $t('form.Email') }}</label>
                  <div class="relative text-white-dark">
                    <input
                      id="Email"
                      v-model="email"
                      type="email"
                      :placeholder="`${$t('form.placeHolderEmail')}...`"
                      class="form-input ps-10 placeholder:text-white-dark"
                    >
                    <span class="absolute start-4 top-1/2 -translate-y-1/2">
                      <Icon name="hugeicons:mail-02" />
                    </span>
                  </div>
                </div>
                <div>
                  <label for="Password">{{ $t('form.Password') }}</label>
                  <div class="relative text-white-dark">
                    <input
                      id="Password"
                      v-model="password"
                      class="form-input ps-10 placeholder:text-white-dark"
                      :type="showPassword ? 'text' : 'password'"
                      :placeholder="`${$t('form.placeHolderPassword')}...`"
                    >
                    <span class="absolute start-4 top-1/2 -translate-y-1/2">
                      <Icon name="hugeicons:circle-password" />
                    </span>
                    <span
                      v-if="password.length > 0"
                      class="absolute end-4 top-1/2 -translate-y-1/2 cursor-pointer"
                      @click="togglePassword"
                    >
                      <Icon :name="showPassword ? 'hugeicons:view' : 'hugeicons:view-off'" />
                    </span>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn bg-primary text-white p-2 !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]"
                >
                  {{ $t('form.SignIn') }}
                </button>
              </form>
              <div class="relative my-7 text-center md:mb-9">
                <span class="absolute inset-x-0 top-1/2 h-px w-full -translate-y-1/2 bg-white-light dark:bg-white-dark" />
              </div>
              <div class="text-center dark:text-white">
                {{ $t('NotAccount') }}
                <NuxtLink
                  to="#"
                  class="uppercase text-primary underline transition hover:text-black dark:hover:text-white"
                >
                  {{ $t('SignUp') }}
                </NuxtLink>
              </div>
            </div>
            <div class="w-full">
              <div>
                <img
                  class="w-full mx-auto"
                  src="/assets/img/authentication-bg.jpg"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
